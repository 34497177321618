import Vue from 'vue'
import VueI18n from 'vue-i18n'
import db from '@/utils/localStorage'
import enLocale from './en'
import zhLocale from './zh'
import vnLocale from './vn'

import elEnLocale from 'element-ui/lib/locale/lang/en'
import elZhLocale from 'element-ui/lib/locale/lang/zh-CN'

import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n)

const messages = {

  en: {
    ...elEnLocale,
    ...enLocale
  },
  zh: {
    ...elZhLocale,
    ...zhLocale
  },
  vn: {
    ...elEnLocale,
    ...vnLocale
  },
};

export function getLanguage() {
  const defaultLang = 'en'
  try {
    const chooseLanguage = db.get('LANGUAGE');

    if (typeof chooseLanguage === 'object') {
      // can not get from localStorage, set default value
      // object
      return defaultLang
    } else {
      // string
      return chooseLanguage
    }
  } catch (error) {
    return defaultLang
  }

  // const chooseLanguage = db.get('LANGUAGE');

  // if (chooseLanguage) return chooseLanguage;

  // // if has not choose language
  // const language = (navigator.language || navigator.browserLanguage).toLowerCase();
  // const locales = Object.keys(messages);
  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return locale;
  //   }
  // }
  
  // return 'en';
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages,
  silentTranslationWarn: true
});
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
